//temporary for call checkstock from store
import axios from '@/axios-external.js'

export default {    
  getAddressByPostal({ commit }, payload) {
    console.log(payload)
    return new Promise((resolve, reject) => {
      axios.get('/api/v1/sales-and-purchase/drcc/onemap',
      {
        params:{
          searchVal:payload,
          getAddrDetails:'Y',
          returnGeom:'N'
        }
      }).then((response) => {
        resolve(response.data.results)
      }).catch((e) => {
        console.log(e)
        reject(e)
      });
    });
  },
}
