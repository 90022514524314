import axios from "@/axios.js";

export default {
  createWarranty(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("sales-and-purchase/drcc/warranty/create-warranty", payload)
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
        });
    });
  },
  getInternalWarranty({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get("sales-and-purchase/drcc/submitted-warranty/list", {
          params: {
            type: "Internal",
            ...params,
          },
        })
        .then((response) => {
          commit("SET_INTERNAL_WARRANTY", response.data.data);
          resolve(response.data.data);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
        });
    });
  },
  getExternalWarranty({ commit }, params) {
    console.log("params", params);
    return new Promise((resolve, reject) => {
      axios
        .get("sales-and-purchase/drcc/submitted-warranty/list", {
          params: {
            ...params,
          },
        })
        .then((response) => {
          if (!params.forDownload) {
            commit("SET_EXTERNAL_WARRANTY", response.data.data);
          }
          resolve(response.data);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
        });
    });
  },
  getAllWarranty({ commit }, params) {
    console.log("params", params);
    return new Promise((resolve, reject) => {
      axios
        .get("sales-and-purchase/drcc/all-warranty/list", {
          params: {
            ...params,
          },
        })
        .then((response) => {
          commit("SET_ALL_WARRANTY", response.data.data);
          resolve(response.data);
        })
        .catch((err) => {
          console.log({ err });
          reject(err);
        });
    });
  },
  deleteExternalWarranty({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `sales-and-purchase/drcc/submitted-warranty/delete-warranty-paperless/${id}`
        )
        .then((response) => {
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(false);
        });
    });
  },
  deleteExternalWarrantyItem({ commit }, payload) {
    let { id, warrantyId } = payload;
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `sales-and-purchase/drcc/submitted-warranty/delete-external-warranty-item/${id}`,
          {
            params: { warrantyId },
          }
        )
        .then((response) => {
          resolve(true);
        })
        .catch((e) => {
          reject(false);
        });
    });
  },
  getPaperlessStatistic(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("sales-and-purchase/drcc/warranty/paperless-statistics")
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
        });
    });
  },
  getBaseWarranty(context, query) {
    return new Promise((resolve, reject) => {
      axios
        .get("sales-and-purchase/drcc/base-warranty")
        .then((response) => {
          resolve([response.data.data]);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
        });
    });
  },
  getBaseWarrantyAll(context, query) {
    return new Promise((resolve, reject) => {
      axios
        .get("sales-and-purchase/drcc/base-warranty-all")
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
        });
    });
  },
  updateBaseWarranty(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`sales-and-purchase/drcc/base-warranty`, payload)
        .then((response) => {
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
        });
    });
  },
  updateFlagExpiry(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`sales-and-purchase/drcc/flag-expiry`, payload)
        .then((response) => {
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
        });
    });
  },
  updateOptionWarranty(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`sales-and-purchase/drcc/option-warranty`, payload)
        .then((response) => {
          resolve(true);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
        });
    });
  },
  getCompressorWarrantyByQuery(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("sales-and-purchase/drcc/compressor-warranty", {
          params: { ...payload },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
        });
    });
  },
  listCompressorWarranty(context) {
    return new Promise((resolve, reject) => {
      axios
        .get("sales-and-purchase/drcc/compressor-warranty")
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
        });
    });
  },
  createCompressorWarranty(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("sales-and-purchase/drcc/compressor-warranty", payload)
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
        });
    });
  },
  getCompressorWarranty(context, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`sales-and-purchase/drcc/compressor-warranty/${id}`)
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
        });
    });
  },
  updateCompressorWarranty(context, payload) {
    let { _id } = payload;
    return new Promise((resolve, reject) => {
      axios
        .put(`sales-and-purchase/drcc/compressor-warranty/${_id}`, payload)
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
        });
    });
  },
  deleteCompressorWarranty(context, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`sales-and-purchase/drcc/compressor-warranty/${id}`)
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
        });
    });
  },
  updateWarranty(context, payload) {
    let { _id } = payload;
    return new Promise((resolve, reject) => {
      axios
        .put(`sales-and-purchase/drcc/edit-warranty/${_id}`, payload)
        .then((response) => {
          // console.log(response)
          resolve(response.data.data);
        })
        .catch((e) => {
          console.log({ e });
          reject(e);
        });
    });
  },
  cancelWarranty(context, payload) {
    let { _id } = payload;
    return new Promise((resolve, reject) => {
      axios
        .put(`sales-and-purchase/drcc/warranty/cancel-warranty/${_id}`, payload)
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((e) => {
          console.log(e);
          throw e;
        });
    });
  },
  deregisterWarranty(context, payload) {
    let { _id } = payload;
    return new Promise((resolve, reject) => {
      axios
        .put(`sales-and-purchase/drcc/warranty/deregister-warranty-paperless/${_id}`, payload)
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((e) => {
          console.log(e);
          throw e;
        });
    });
  },
  getIndividualWarranty({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get("sales-and-purchase/drcc/warranty-individual")
        .then((response) => {
          // commit('SET_EXTERNAL_WARRANTY', response.data.data)
          resolve(response.data.data);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
        });
    });
  },
  updateWarrantyStatus(context, payload) {
    let { _id } = payload;
    return new Promise((resolve, reject) => {
      axios
        .put(`sales-and-purchase/drcc/update-inventory/${_id}`, payload)
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
        });
    });
  },
  massUpdateEmail(context, payload) {
    console.log("Payload", payload);
    return new Promise((resolve, reject) => {
      axios
        .post("sales-and-purchase/drcc/mass-update-email", payload)
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
        });
    });
  },
  getOptionWarranty({commit}){
    return new Promise((resolve, reject) => {
      axios
        .get("sales-and-purchase/drcc/option-warranty")
        .then((response) => {
          commit('SET_OPTION_WARRANTY',{
            warranty:response.data.data,
            minValue:(response.data.minValue || 12)
          })
          resolve(response.data.data);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
        });
    });
  }
};
