import state from './moduleTermConditionState'
import mutations from './moduleTermConditionMutations'
import actions from './moduleTermConditionActions'
import getters from './moduleTermConditionGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
