export default {
  AUTH_USER: (state, payload) => {
    state.token = payload
  },
  LOGIN_OTP: (state, payload) => {
    state.loginOtp.pop()
    state.loginOtp.push(payload)
  },
  USER_DETAIL: (state, payload) => {
    state.activeUser = payload
  },

  SET_PREV_AUTH_PAGE: (state, payload) => {
    state.prevAuthPage = payload;
  },
  USER_ID: (state, payload) => {
    state.userID = payload;
  },
  UPDATE_USER: (state, payload) => {
    let user = state.user.find(u => u.id === payload.id)
    for (let [key, value] of Object.entries(payload)) {
      if ((user[key] || key === 'roleRef' || key === 'name') && user[key] !== value) {
        user[key] = value
      }
    }
  },
  FETCH_USER: (state, payload) => {
    state.user = payload
  },
}
