export default {
  FETCH_INDUSTRY_TYPE:(state,payload)=>{
    state.industryTypes = payload
  },
  DELETE_INDUSTRY_TYPE: (state, payload) => {
    let i = state.industryTypes.findIndex(u => u.id === payload)
    state.industryTypes.splice(i, 1)
  },
  ADD_INDUSTRY_TYPE: (state, payload) => {
    state.industryTypes.push(payload)
  },
  UPDATE_INDUSTRY_TYPE: (state, payload) => {
    let industryType = state.industryTypes.find(u => u.id === payload.id)
    for (let [key, value] of Object.entries(payload)) {
      if (industryType[key] && industryType[key] !== value) {
        industryType[key] = value
      }
    }
  }
}
