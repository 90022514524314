export default {
  GET_DASHBOARD_PASSWORD:(state,payload )=>{
    state.dashboardPassword = payload
  },
  GET_DEALER_DASHBOARD: (state, payload) => {
    state.dashboard = payload;
  },
  GET_DO_CREATED: (state, payload) => {
    state.doCreated = payload;
  },
  GET_DO_PROCESSING: (state, payload) => {
    state.doProcessing = payload;
  },
  GET_DELIVERED: (state, payload) => {
    state.delivered = payload;
  },

  GET_WARRANTY: (state, payload) => {
    state.warranty = payload;
  },

  SET_FILTER_DASHBOARD: (state, payload) => {
    state.filterDashboard = payload;
  },

  GET_SALES_ADMIN: (state, payload) => {
    state.salesAdmin = payload;
  }
};
