/* eslint-disable arrow-parens */
/* eslint-disable semi */
import Vue from 'vue';
import { BootstrapVue, IconsPlugin, ModalPlugin } from 'bootstrap-vue';
import VueCompositionAPI from '@vue/composition-api';
// import VueHtmlToPaper from 'vue-html-to-paper';
import JsonExcel from 'vue-json-excel';

import router from './router';
import store from './store';
import App from './App.vue';
import vSelect from 'vue-select'



// Global Components
import './global-components';

// 3rd party pluginss
import '@/libs/portal-vue';

Vue.use(BootstrapVue)

Vue.use(IconsPlugin)

const formatOptions = { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'Asia/Singapore' };
const formatter = new Intl.DateTimeFormat('en-SG', formatOptions);
Vue.filter('formatDate', (value) => {
  try {
    return formatter.format(new Date(value));
  } catch (e) {
    return value;
  }
});

// BSV Plugin Registration
Vue.use(ModalPlugin);

// Composition API
Vue.use(VueCompositionAPI);

// Vue HTML to Paper
// Vue.use(VueHtmlToPaper)

Vue.component('downloadExcel', JsonExcel)

Vue.component('v-select', vSelect)
import 'vue-select/dist/vue-select.css';

// import core styles
require('@core/scss/core.scss');

// import assets styles
require('@/assets/scss/style.scss');

import VueSession from 'vue-session'
Vue.use(VueSession, {
  persist: true
})

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
