export default {
  SET_ORDER: (state, payload) => {
    state.order = payload;
  },
  SET_PCODE: (state, payload) => {
    state.pcode = payload;
  },
  SET_INVENTORY: (state, payload) => {
    state.inventory = payload;
  },
  SET_REFERENCE: (state, payload) => {
    state.promo = payload;
  },
  SET_DO: (state, payload) => {
    state.deliveryOrder = payload
  },
  SET_WARRANTY: (state, payload) => {
    state.warranty = payload
  }
}
