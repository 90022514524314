export default {
  SET_PURCHASE: (state, payload) => {
    state.purchase = payload;
  },
  SET_FAILPURCHASE: (state, payload) => {
    state.failPurchase = payload;
  },
  GET_PURCHASE_ID:(state,payload)=>{    
    state.purchaseById = payload;
  },
  DELETE_PURCHASE: (state, payload) => {
    let i = state.purchase.find(u => u.id === payload)
    state.purchase.splice(i, 1)
  }
}
