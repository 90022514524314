import state from './industryTypeState'
import mutations from './industryTypeMutations'
import actions from './industryTypeActions'
import getters from './industryTypeGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
