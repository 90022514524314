import axios from "@/axios.js";

export default { 
  //To send PDF document to FTP Server
  
  /**
   * 
   * @param {*} param0 
   * @param {pdfData : string - PDF file encoded into base64 , sapDo:string - sapDO number} deliveryData 
   * @returns 
   */
  sendBatchToFTP({ commit }) {
    return new Promise((resolve, reject) => {
      axios.post('sales-and-purchase/drcc/delivery/batch-ftp').then((response) => {
        resolve(response.data.data);
      }).catch((e) => {
        console.log(e)
        reject(e);
      });
    });
  }, 
  sendReturnToFTP({ commit },id) {
    return new Promise((resolve, reject) => {
      axios.post('sales-and-purchase/drcc/delivery/return-ftp/'+id).then((response) => {
        resolve(response.data.data);
      }).catch((e) => {
        console.log(e)
        reject(e);
      });
    });
  },
  sendManualReturnToFTP({ commit },id) {
    return new Promise((resolve, reject) => {
      axios.post('sales-and-purchase/drcc/delivery/return-ftp/'+id,{manual:true}).then((response) => {
        resolve(response.data.data);
      }).catch((e) => {
        console.log(e)
        reject(e);
      });
    });
  }, 
  //this is store for try real callback Delivery access by SAP (testing on internal RSP system)
  createDO({ commit }, deliveryData) {
    return new Promise((resolve, reject) => {
      axios
        .post("sales-and-purchase/drcc/callback/createDo", deliveryData)
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
        });
    });
  },
  callbackUpdateDO({ commit }, deliveryData) {
    return new Promise((resolve, reject) => {
      axios
        .post("sales-and-purchase/drcc/callback/editDO", deliveryData)
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
        });
    });
  },
  callbackCancelDO({ commit }, deliveryData) {
    console.log(deliveryData);
    return new Promise((resolve, reject) => {
      axios
        .post("sales-and-purchase/drcc/callback/cancelDo", deliveryData)
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
        });
    });
  },
  //END - this is store for try real callback Delivery access by SAP (testing on internal RSP system)

  //this is for internal RSP edit delivery related. different with callback for only access by SAP
  //DON'T TOUCH THIS UNLESS YOU REALLY WANT TO BE HURT REAL BAD - Ahmad
  updateDO({ commit }, { idDelivery, deliveryData }) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          "sales-and-purchase/drcc/delivery/editDO/" + idDelivery,
          deliveryData
        )
        .then((response) => {
          console.log("response.data", response.data);
          resolve(response.data);
        })
        .catch((e) => {
          console.log({ e });
          reject(e);
        });
    });
  },
  //END - this is for internal RSP edit delivery related. different with callback for only access by SAP
  //Edit DO Status for utility tools
  editDOStatus({ commit }, { idDelivery, deliveryData }) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          "sales-and-purchase/drcc/delivery/editDOStatus/" + idDelivery,
          deliveryData
        )
        .then((response) => {
          console.log("response.data", response.data);
          resolve(response.data);
        })
        .catch((e) => {
          console.log({ e });
          reject(e);
        });
    });
  },
  //END Edit DO Status
  detailDelivery({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`sales-and-purchase/drcc/delivery-order/${id}`)
        .then((response) => {
          commit("GET_DELIVERY_ID", response.data.data);
          resolve(response.data.data);
        })
        .catch((e) => {
          console.log(e);
        });
    });
  },

  getDeliveryOrder({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get("sales-and-purchase/drcc/delivery-order", {
          params: params,
        })
        .then((response) => {
          console.log("response", response);
          commit("GET_DO", response.data.data);
          resolve(response.data);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
        });
    });
  },
  getDeliveryOrderByDoNumber({ commit }, sapDo) {
    return new Promise((resolve, reject) => {
      axios
        .get("sales-and-purchase/drcc/delivery-order", {
          params:{sapDo:sapDo},
        })
        .then((response) => {
          console.log("response", response);
          resolve(response.data.data);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
        });
    });
  },
  //Note: this temporary for needed resend to SAP for retrigger api module
  resendToSAP({ commit }, { idDelivery, deliveryData }) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          "sales-and-purchase/drcc/delivery/resendSAP/" + idDelivery,
          deliveryData
        )
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
        });
    });
  },

  //this is bulk unscan after cancel delivery -> to be deleted
  bulkUnscan({ commit }, { idDelivery, deliveryData }) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          "sales-and-purchase/drcc/delivery/bulkUnscan/" + idDelivery,
          deliveryData
        )
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
        });
    });
  },
  sendToFTP({ commit }, { sapDo,pdfData }) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          "sales-and-purchase/drcc/delivery/toFTP/",
          {
            sapDo:sapDo,
            pdfData:pdfData
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
        });
    });
  },
  getMassDoUpdateList({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get("sales-and-purchase/drcc/mass-do-list", {
          params: params,
        })
        .then((response) => {
          console.log("response mass do", response);
          commit("GET_DO", response.data.data);
          resolve(response.data);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
        });
    });
  },
  updateMassDo({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          "sales-and-purchase/drcc/mass-do-update", data.data
        )
        .then((response) => {
          console.log("response.data", response.data);
          resolve(response.data);
        })
        .catch((e) => {
          console.log({ e });
          reject(e);
        });
    });
  },
  getListAmkSparePart({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get("sales-and-purchase/drcc/clear-amk-sparepart", {
          params: params,
        })
        .then((response) => {
          console.log("response getListAmkSparePart", response);
          commit("GET_DO", response.data.data);
          resolve(response.data);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
        });
    });
  },
  clearAmkSparePart({ commit }, payload) {
    console.log("params", payload);
    return new Promise((resolve, reject) => {
      axios
        .post("sales-and-purchase/drcc/clear-amk-sparepart", payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
        });
    });
  },
  replaceDo({ commit }, payload) {
    console.log("params", payload);
    return new Promise((resolve, reject) => {
      axios
        .post("sales-and-purchase/drcc/delivery/replaceDo", payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
        });
    });
  },
};
