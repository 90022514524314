import state from './moduleRoleState'
import mutations from './moduleRoleMutations'
import actions from './moduleRoleActions'
import getters from './moduleRoleGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
