import axios from '@/axios.js'

export default {
  getIndustryTypes({ commit },params) {
    return new Promise((resolve, reject) => {
      axios.get('/sales-and-purchase/drcc/industry-type', { params: { ...params }}).then((response) => {
        commit('FETCH_INDUSTRY_TYPE', response.data.data)
        resolve(response.data);
      
      }).catch((e) => {
        console.log(e)
        reject(e)
      })
    });
  },
  getIndustryType({context}, id) {
    console.info('get id ',id)
    return new Promise((resolve, reject) => {
      axios.get(`sales-and-purchase/drcc/industry-type/${id}`).then((response) => {
        resolve(response.data.data)
      }).catch((e) => {
        
        console.log(e);
        reject(e)
      });
    })
  },
  addIndustryType: ({commit}, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/sales-and-purchase/drcc/industry-type', payload)
        .then(res => {
          commit('ADD_INDUSTRY_TYPE', res.data.data)
          resolve()
        })
        .catch(err => reject(err))
    })
  },
  updateIndustryType({ commit }, payload) {
    var {_id}= payload
    console.info('paylaod ',payload)
    return new Promise((resolve, reject) => {
      axios
        .put('/sales-and-purchase/drcc/industry-type/' + _id, payload)
        .then(() => {
          //commit('DELETE_CHANNEL', id)
          resolve()
        })
        .catch(err => reject(err))
    })
  },
  
  deleteIndustryType({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete('/sales-and-purchase/drcc/industry-type/' + id)
        .then(() => {
          commit('DELETE_CHANNEL', id)
          resolve()
        })
        .catch(err => reject(err))
    })
  },
  
  
}
