export default {
  getDashboardPassword: (state )=> state.dashboardPassword,
  getDealerDashboard: (state) => state.dashboard,
  getDealerSalesAdmin: (state) => state.salesAdmin,
  getDoCreated: (state) => state.doCreated,
  getDoProcessing: (state) => state.doProcessing,
  getDelivered: (state) => state.delivered,
  getWarranty: (state) => state.warranty,
  filterDashboard: (state) => state.filterDashboard,
};
