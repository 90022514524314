import axios from "@/axios.js";

export default {
  getSales({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get("/sales-and-purchase/sales/drcc", {
          params: payload,
        })
        .then((response) => {
          commit("SET_SALES", response.data.data);
          resolve(response.data);
        })
        .catch((e) => {
          console.error(e);
          reject(e);
        });
    });
  },
  updateDraft({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/sales-and-purchase/sales-draft", payload)
        .then((response) => {
          resolve(response);
        })
        .catch((e) => {
          console.log({ e });
          reject(e);
        });
    });
  },
  addSale({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/sales-and-purchase/sales", payload)
        .then((response) => {
          resolve(response);
        })
        .catch((e) => {
          console.error(e);
          reject(e);
        });
    });
  },
  detailSales: ({ commit }, { id }) => {
    return new Promise((resolve, reject) => {
      axios
        .get("/sales-and-purchase/sales/detail/" + id)
        .then((res) => {
          // console.log(res.data.data)
          commit("GET_SALES_ID", res.data.data);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          //alert(err);
          reject(err);
        });
    });
  },
};
