import axios from '@/axios';

export default {
    verifyDo(context, payload) {
        return new Promise((resolve, reject) => {
            axios.post('sales-and-purchase/equipment-warranty/list-delivery-order',payload 
            ).then((response) => {
                // console.log('list DO',response.data.data)
                resolve(response.data.data);
            }).catch((e) => {
                console.log(e);
                reject(e);
            })
        });
    },
    getEquipmentWarranty({ commit }, params) {
        return new Promise((resolve, reject) => {
            axios.get('sales-and-purchase/equipment-warranty', {params:params}).then((response) => {
                // console.log('response semua equipment',response.data.data)
                commit('SET_EQUIPMENT_WARRANTY', response.data.data);
                resolve(response.data);
            }).catch((e) => {
                console.log(e);
            })
        });
    },
    saveEquipment(context, payload) {
        return new Promise((resolve, reject) => {
            axios.post('sales-and-purchase/equipment-warranty', payload).then((response) => {
                resolve(response.data.data);
            }).catch((e) => {
                console.log(e);
                reject(e);
            });
        });
    },
    searchSO(context, payload) {
        return new Promise((resolve, reject) => {
            axios.post('sales-and-purchase/equipment-warranty/search-sale-order', payload)
            .then((response) => {
                resolve(response.data.data);
            }).catch((e) => {
                console.log(e);
                reject(e)
            })
        });
    },
    deleteEquipment(context, id) {
        return new Promise((resolve, reject) => {
            axios.delete(`sales-and-purchase/equipment-warranty/${id}`).then((response) => {
                resolve(response.data.data);
            }).catch((e) => {
                console.log(e);
                reject(e)
            })
        });
    },
    listSalesOrder(context, query) {
        return new Promise((resolve, reject) => {
            axios.get('sales-and-purchase/equipment-warranty/list-sale-order').then((response) => {
                resolve(response.data.data);
            }).catch((e) => {
                console.log(e)
                reject(e)
            })
        });
    },
    getEquipment(context, id) {
        return new Promise((resolve, reject) => {
            axios.get(`sales-and-purchase/equipment-warranty/${id}`).then((response) => {
                // console.log('respond store',response.data.data )
                resolve(response.data.data);
            }).catch((e) => {
                console.log(e)
                reject(e)
            })
        });
    },
    updateEquipment(context, payload) {
        let { _id } = payload;
        return new Promise((resolve, reject) => {
            axios.put(`sales-and-purchase/equipment-warranty/${_id}`, payload).then((response) => {
                resolve(response.data.data);
            }).catch((e) => {
                console.log(e)
                reject(e)
            })
        });
    }
}