import axios from '@/axios';

export default {
    namespaced: true,
    state() {
        return {
            categories: [],
        }
    },
    mutations: {
        setCategories(state, data) {
            state.categories = data;
        }
    },
    actions: {
        getCategories({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get('sales-and-purchase/drcc/item/categories').then((response) => {
                    commit('setCategories', response.data.data);
                    resolve(response.data.data);
                }).catch((e) => {
                    console.log(e)
                    reject(e);
                })
            })
        }
    }
}