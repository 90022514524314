import axios from '@/axios.js'

export default {
  addItemMaster({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('sales-and-purchase/drcc/item', payload).then((response) => {
        commit('ADD_DATA',response.data.data)
        resolve()
      }).catch((e) => {
        console.log(e)
        reject(e)
      });
    });
  },
  addDealerMaster({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('sales-and-purchase/drcc/dealer', payload).then((response) => {
        commit('ADD_DATA',response.data.data)
        resolve()
      }).catch((e) => {
        console.log(e)
        reject(e)
      });
    });
  },
  addPriceMaster({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('sales-and-purchase/drcc/price', payload).then((response) => {
        commit('ADD_DATA',response.data.data)
        resolve()
      }).catch((e) => {
        console.log(e)
        reject(e)
      });
    });
  },
  addCompressorWarranty({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('sales-and-purchase/drcc/compressor', payload).then((response) => {
        commit('ADD_DATA',response.data.data)
        resolve()
      }).catch((e) => {
        console.log(e)
        reject(e)
      });
    });
  },
  addWarrantyReg({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('sales-and-purchase/drcc/warranty-reg', payload).then((response) => {
        commit('ADD_DATA',response.data.data)
        resolve(true)
      }).catch((e) => {
        let errorMessage=""
        console.info('error response',e.response)
        if(e.response==undefined){
          resolve({status:false,message:"Connection error on Warranty "+payload.cert+", retrying"})
        }else if(e.response.status==400){
          try{
            errorMessage= e.response.data.errors[0].message
            resolve({status:true,message:errorMessage})
          }catch(err){
            //e.response may not return message as expected
            resolve({status:true,message:"Uncaught Error on Warranty "+payload.cert+". Please retry uploading"})
          }
        }
        try{
          errorMessage= e.response.data.errors[0].message
          resolve({status:false,message:errorMessage})
        }catch(err){
          //e.response may not return message as expected
          resolve({status:false,message:"Uncaught Error on Warranty "+payload.cert+". Please retry uploading"})
        }
      });
    });
  },
  addDORSP({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('sales-and-purchase/drcc/DO', payload).then((response) => {
        commit('ADD_DATA',response.data.data)
        resolve({status:true})
      }).catch((e) => {
        let errorMessage=""
        console.info('error response',e.response)
        if(e.response==undefined){
          resolve({status:false,message:"Connection error on DO "+payload.sapDo+", retrying"})
        }else if(e.response.status==400){
          try{
            errorMessage= e.response.data.errors[0].message
            resolve({status:true,message:errorMessage})
          }catch(err){
            //e.response may not return message as expected
            resolve({status:true,message:"Uncaught Error on DO "+payload.sapDo+". Please retry uploading"})
          }
        }
        try{
          errorMessage= e.response.data.errors[0].message
          resolve({status:false,message:errorMessage})
        }catch(err){
          //e.response may not return message as expected
          resolve({status:false,message:"Uncaught Error on DO "+payload.sapDo+". Please retry uploading"})
        }
        
      });
    });
  },
  addCustomerWarranty({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('sales-and-purchase/drcc/customer-warranty', payload).then((response) => {
        commit('ADD_DATA',response.data.data)
        resolve()
      }).catch((e) => {
        console.log(e)
        reject(e)
      });
    });
  },
  addCampaignMaster({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('sales-and-purchase/drcc/campaign-master', payload).then((response) => {
        commit('ADD_DATA',response.data.data)
        resolve()
      }).catch((e) => {
        console.log(e)
        reject(e)
      });
    });
  },
  addCampaignBatch({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('sales-and-purchase/drcc/campaign-batch', payload).then((response) => {
        commit('ADD_DATA',response.data.data)
        resolve()
      }).catch((e) => {
        console.log(e)
        reject(e)
      });
    });
  },
  addCampaignNumbering({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('sales-and-purchase/drcc/campaign-numbering', payload).then((response) => {
        commit('ADD_DATA',response.data.data)
        resolve()
      }).catch((e) => {
        console.log(e)
        reject(e)
      });
    });
  },
  addCampaignReference({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('sales-and-purchase/drcc/campaign-reference', payload).then((response) => {
        commit('ADD_DATA',response.data.data)
        resolve()
      }).catch((e) => {
        console.log(e)
        reject(e)
      });
    });
  },
  addInventoryRSP({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('sales-and-purchase/drcc/inventory', payload).then((response) => {
        commit('ADD_DATA',response.data.data)
        resolve(true)
      }).catch((e) => {
        let errorMessage=""
        console.info('error response',e.response)
        if(e.response==undefined){
          resolve({status:false,message:"Connection error on Inventory "+payload.barcode+", retrying"})
        }else if(e.response.status==400){
          try{
            errorMessage= e.response.data.errors[0].message
            resolve({status:true,message:errorMessage})
          }catch(err){
            //e.response may not return message as expected
            resolve({status:true,message:"Uncaught Error on Inventory "+payload.barcode+". Please retry uploading"})
          }
        }
        try{
          errorMessage= e.response.data.errors[0].message
          resolve({status:false,message:errorMessage})
        }catch(err){
          //e.response may not return message as expected
          resolve({status:false,message:"Uncaught Error on Inventory "+payload.barcode+". Please retry uploading"})
        }
      });
    });
  },
  addPOSMaster({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('sales-and-purchase/drcc/poscode-master', payload).then((response) => {
        commit('ADD_DATA',response.data.data)
        resolve()
      }).catch((e) => {
        console.log({e})
        reject(e)
      });
    });
  },
  
  
}