import state from "./dashboardState";
import mutations from "./dashboardMutations";
import actions from "./dashboardActions";
import getters from "./dashboardGetters";

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
