export default {
  FETCH_TERMCONDITION: (state, payload) => {
    state.TermCondition = payload
  },
  ADD_TERMCONDITION: (state, payload) => {
    state.TermCondition.push(payload)
  },
  UPDATE_TERMCONDITION: (state, payload) => {
    let TermCondition = state.TermCondition.find(u => u.id === payload.id)
    for (let [key, value] of Object.entries(payload)) {
      if (TermCondition[key] && TermCondition[key] !== value) {
        TermCondition[key] = value
      }
    }
  },
}