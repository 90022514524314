import axios from '@/axios';

export default {
    namespaced: true,
    state() {
        return {
            campaigns: [],
        }
    },
    mutations: {
        setCampaigns(state, campaigns) {
            state.campaigns = campaigns;
        }
    },
    actions: {
        getCampaigns({ commit }) {

            return new Promise((resolve, reject) => {
                axios.get('sales-and-purchase/drcc/campaigns').then((response) => {
                    commit('setCampaigns', response.data.data);
                    resolve(response.data.data);
                    
                }).catch((e) => {
                    console.log(e)
                    reject(e)
                })
              })
        },
        getCampaign({ commit }, idCampaign) {
            return new Promise((resolve, reject) => {
                axios.get(`sales-and-purchase/drcc/campaigns/${idCampaign}`).then((response) => {
                    
                    resolve(response.data.data);
                }).catch((e) => {
                    console.log(e)
                    reject(e);
                })
            })
        },
        updateCampaign(context, payload) {
            let { _id } = payload;
            delete(payload._id);
            return new Promise((resolve, reject) => {
                axios.put(`sales-and-purchase/drcc/campaigns/${_id}`, payload).then((response) => {
                    resolve(response.data.data)
                }).catch((e) => {
                    console.log(e);
                    reject(e);
                })
            })
        },
        createCampaign(context, payload) {
            let { _id } = payload;
            return new Promise((resolve, reject) => {
                axios.post(`sales-and-purchase/drcc/campaigns`, payload).then((response) => {
                    resolve (response.data.data)
                }).catch((e) => {
                    console.log(e);
                    reject(e);
                })
            })
        },
        deleteCampaign(context, id) {
            return new Promise((resolve, reject) => {
                axios.delete(`sales-and-purchase/drcc/campaigns/${id}`).then((response) => {
                    resolve(response.data.data)
                }).catch((e) => {
                    console.log(e);
                    reject(e);
                })
            });
        },
    }
}