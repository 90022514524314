import Vue from "vue";
import Vuex from "vuex";

// Modules
import app from "./app";
import appConfig from "./app-config";
import verticalMenu from "./vertical-menu";

import state from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

// Auth
import moduleAuth from "./auth/moduleAuth";

// Log
import moduleLog from './auth/log/moduleLog'

// Role
import moduleRole from "./auth/role/moduleRole";

// Industry Type
import moduleIndustryType from "./industry-type/moduleIndustryType";

// Channel
import moduleChannel from "./channel/moduleChannel";

// Dealer
import moduleDealer from "./dealer/moduleDealer";

// Dashboard
import moduleDashboard from "./dashboard/moduleDashboard";

// Item
import moduleItem from "./item/moduleItem";

// Sale
import moduleSale from "./sales/moduleSale";

import modulePurchase from "./purchase/modulePurchase";

// Delivery
import moduleDelivery from "./delivery/moduleDelivery";

// Report
import moduleReport from "./report/moduleReport";

// Warehouse
// import moduleWarehouse from './warehouse/moduleWarehouse'

// Installer
// import moduleInstaller from './installer/moduleInstaller';

import moduleGST from "./gst/moduleGst";

import moduleInventory from "./inventory/moduleInventory";

import moduleWarranty from "./warranty/moduleWarranty";

import moduleFilter from "./filter/moduleFilter";

import moduleProjectWarranty from "./project-warranty/moduleProjectWarranty";

import modulePCode from "./promo/indexModule";

import moduleCampaign from "./campaign/moduleCampaign";

import moduleCategory from "./category/moduleCategory";

import moduleSend from "./sendSAP/moduleSend";

import moduleSystemPromo from './system-promo/moduleSystemPromo'

import moduleTermCondition from "./term-condition/moduleTermCondition";
import modulePendingReview from "./pending-review/modulePendingReview";

import moduleMigration from './migration/moduleMigration'
import moduleMapAPI from "./mapAPI/moduleMapAPI";

import moduleEquipmentWarranty from "./equipment-warranty/moduleEquipmentWarranty";

Vue.use(Vuex)

export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    app,
    appConfig,
    verticalMenu,

    // Auth
    auth: moduleAuth,

    // Log
    log: moduleLog,

    // Role
    role: moduleRole,

    //Industry Type
    industryType: moduleIndustryType,
    // Channel
    channel: moduleChannel,

    // Dealer
    dealer: moduleDealer,

    // Dashboard
    dashboard: moduleDashboard,

    // Item
    item: moduleItem,

    // Sale
    sales: moduleSale,

    purchase: modulePurchase,

    // Delivery
    delivery: moduleDelivery,

    // Report
    report: moduleReport,

    // Warehouse
    // warehouse: moduleWarehouse,

    // Installer
    // installer: moduleInstaller,

    GST: moduleGST,

    systemPromo:moduleSystemPromo,

    inventory: moduleInventory,

    warranty: moduleWarranty,

    filter: moduleFilter,

    projectWarranty: moduleProjectWarranty,

    equipmentWarranty: moduleEquipmentWarranty,

    pcode: modulePCode,

    campaign: moduleCampaign,

    category: moduleCategory,

    sendSAP : moduleSend,

    migration : moduleMigration,

    TermCondition : moduleTermCondition,
    PendingReview : modulePendingReview,

    mapAPI : moduleMapAPI
  },
  strict: process.env.DEV,
});
