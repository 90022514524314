import axios from '@/axios';

export default {
    namespaced: true,
    state() {
        return {
            channelsList: [],
            items: [],
            pcodes: [],
            batchPromoCode: [],
            campaignNumber:[]
        }
    },
    mutations: {
        setPCodeItems(state, items) {
            state.items = items;
        },
        setPCodes(state, pcodes) {
            state.pcodes = pcodes;
        },
        setBatchPromoCode(state, batchPromoCode) {
            state.batchPromoCode = batchPromoCode;
        },
        setCampaignNumber(state,campaignNumber){
            state.campaignNumber = campaignNumber
        }
    },
    getters: {
        toModelSelection(state) {
            return state.items.map((item) => {
                return { value: item.itemModel, text: `${item.itemModel} ${item.description}` }
            })
        },
        getPcodes(state){
            return state.pcodes
        }
    },
    actions: {
        getChannels() {
            return new Promise((resolve, reject) => {
                axios.get('sales-and-purchase/drcc/pcode-distchan').then((response) => {
                    resolve(response.data.data);
                }).catch((e) => {
                    console.log(e);
                    reject(e)
                })
            });
        },
        getItemList({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get('sales-and-purchase/drcc/pcode-list-items').then((response) => {
                    commit('setPCodeItems', response.data.data);
                    resolve(response.data.data)
                }).catch((e) => {
                    console.log(e);
                    reject(e)
                })
            });
            return 
        },
        getAllPCodes({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get('sales-and-purchase/drcc/pcode').then((response) => {
                    commit('setPCodes', response.data.data)
                    resolve(response.data.data);
                }).catch((e) => {
                    console.log(e)
                    reject(e);
                })
            });
        },
        getPCode(context, idpCode) {
            return new Promise((resolve, reject) => {
                axios.get(`sales-and-purchase/drcc/pcode/${idpCode}`).then((response) => {
                    resolve(response.data.data);
                }).catch((e) => {
                    console.log(e);
                    reject(e)
                })
            });
        },
        updatePCode(context, payload) {
            let { _id } = payload;
            return new Promise((resolve, reject) => {
                axios.put(`sales-and-purchase/drcc/pcode/${_id}`, payload).then((response) => {
                    resolve(response.data.data);
                }).catch((e) => {
                    console.log(e);
                    reject(e);
                })
            });
        },
        createPCode(context, payload) {
            return new Promise((resolve, reject) => {
                axios.post('sales-and-purchase/drcc/pcode', payload).then((response) => {
                    resolve(response.data.data);
                }).catch((e) => {
                    console.log(e);
                    reject(e);
                })
            });
        },
        deletePCode(context, id) {
            return new Promise((resolve, reject) => {
                axios.delete(`sales-and-purchase/drcc/pcode/${id}`).then((response) => {
                    resolve(response.data);
                }).catch((e) => {
                    console.log(e)
                    reject(e)
                })
            });
            
        },

        // Handle Promocode Batch Related Function
        generatePromoCode(context, payload) {
            return new Promise((resolve, reject) => {
                axios.post('sales-and-purchase/drcc/promocode', payload).then((response) => {
                    resolve(response.data.data);
                }).catch((e) => {
                    console.log(e);
                    reject(e)
                })
              });

        },
        getBatchsPromocode({ commit },id) {
            return new Promise((resolve, reject) => {
                axios.get('sales-and-purchase/drcc/promocode').then((response) => {
                    let filter = response.data.data.filter(x=>{return x.campaign._id === id})
                    commit('setBatchPromoCode', filter);
                    resolve(filter);
                }).catch((e) => {
                    console.log(e);
                    reject(e);
                })
            });
        },
        getCampaignNum({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get('sales-and-purchase/drcc/campaign-number').then((response) => {
                    commit('setCampaignNumber', response.data.data);
                    resolve(response);
                }).catch((e) => {
                    console.log(e);
                    reject(e);
                })
            });
        },
        getBatch(context, idPromoBatch) {
            return new Promise((resolve, reject) => {
                axios.get(`sales-and-purchase/drcc/promocode/${idPromoBatch}`).then((response) => {
                    resolve(response.data.data);
                }).catch((e) => {
                    console.log(e);
                    reject(e);
                })
            }); 
        },
        updateBatch(context, payload) {
            let { _id } = payload;
            return new Promise((resolve, reject) => {
                axios.put(`sales-and-purchase/drcc/promocode/${_id}`, payload).then((response) => {
                    resolve(response.data.data);
                }).catch((e) => {
                    console.log(e);
                    reject(e);
                })
            });
        },
        updatePromoCodeReference(context, payload) {
            return new Promise((resolve, reject) => {
                axios.put('sales-and-purchase/drcc/promocode-reference-update', payload).then((response) => {
                    resolve(response.data.data);
                }).catch((e) => {
                    console.log(e)
                    reject(e);
                })
            });
        },
        updateHoliday(context, payload) {
            let { _id } = payload;
            return new Promise((resolve, reject) => {
                axios.put(`sales-and-purchase/holidays/${_id}`, payload).then((response) => {
                    resolve(response.data.data);
                  }).catch((e) => {
                    console.log(e)
                    reject(e);
                  });
            });
        },
        getHoliday(context, id) {
            return new Promise((resolve, reject) => {
                axios.get(`sales-and-purchase/holidays/${id}`).then((response) => {
                    resolve(response.data.data);
                  }).catch((e) => {
                    console.log(e);
                    reject(e)
                  });
            });
        },
    }
}