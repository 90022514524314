import state from './moduleSystemPromoState'
import mutations from './moduleSystemPromoMutations'
import actions from './moduleSystemPromoActions'
import getters from './moduleSystemPromoGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
