import state from './modulePendingReviewState'
import mutations from './modulePendingReviewMutations'
import actions from './modulePendingReviewActions'
import getters from './modulePendingReviewGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
