import actions from './inventoryActions';
import mutations from './inventoryMutations';
import state from './inventoryState';
import getters from './inventoryGetters';

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters,
}
