import axios from '@/axios.js'

export default {
  getSystemPromo(context, query) {
    return new Promise((resolve, reject) => {
      axios.get('sales-and-purchase/drcc/system-promo').then((response) => {
        resolve( [ response.data.data ] );
      }).catch((e) => {
        console.log(e);
        reject(e)
      })
    }); 
  },
  updateSystemPromo(context, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`sales-and-purchase/drcc/system-promo`, payload).then((response) => {
        resolve(true)
      }).catch((e) => {
        console.log(e);
        reject(e)
      })
    });
  },
  // deleteSystemPromo: ({ commit }, { id }) => {
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .delete('/sales-and-purchase/drcc/SystemPromo/' + id)
  //       .then(() => {
  //         commit('DELETE_SystemPromo', id)
  //         resolve()
  //       })
  //       .catch(err => reject(err))
  //   })
  // },
  // getSystemPromo(context, id) {
  //   return new Promise((resolve, reject) => {
  //     axios.get(`sales-and-purchase/drcc/SystemPromo/${id}`).then((response) => {
  //       resolve(response.data.data)
  //     }).catch((e) => {
        
  //       console.log(e);
  //       reject(e)
  //     });
  //   })
  // },
  // getCurrentSystemPromo: ({ commit }) => {
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .get('sales-and-purchase/drcc/SystemPromo/calculation-date')
  //       .then(res => {
  //         // console.log({res})
  //         commit('FETCH_CURRENT_SystemPromo', res.data.data)
  //         resolve()
  //       })
  //       .catch(err => reject(err))
  //   })
  // },
}