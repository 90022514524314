import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'

import adminRoute from './admin-route';
import customerRoute from './customer-route';
import dealerRoute from './dealer-route';
import warrantyRoute from './warranty-route';
import { sleep } from '@/utils/utils';

Vue.use(VueRouter)


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '',
      component: () => import('../layouts/vertical/LayoutVertical.vue'),
      children: [
        {
          path: '/',
          component: () => import('@/views/Home.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/pages/verified',
          name: 'page-verified',
          component: () => import('@/views/verification/SuccessVerification.vue'),
          meta: {
            layout: 'full',
          },
        },
        {
          path: '/pages/change-password',
          name: 'page-change-password',
          component: () => import('@/views/verification/ChangePassword.vue'),
          meta: {
            layout: 'full',
          },
        },
        {
          path: '/pages/confirm-user/:confirmToken',
          name: 'page-user-confirm-email',
          component: () => import('@/views/verification/ConfirmUser.vue'),
          meta: {
            layout: 'full',
          },
        },
        {
          path: '/pages/workorder',
          name: 'workorder-scan',
          component: () => import('@/views/Warranty.vue'),
          meta: {
            layout: 'full',
          },
        },
        // {
        //   path: '/pages/warrantyreg',
        //   name: 'external-warranty-reg',
        //   component: () => import('@/views/ExternalWarranty.vue'),
        //   meta: {
        //     layout: 'full'
        //   },
        // },
        {
          path: '/user/change-password',
          name: 'user-change-password',
          component: () => import('@/views/ChangePassword.vue'),
          meta: {
            // requiresAuth: true
          },
        },
        {
          path: '/user/view-profile',
          name: 'user-view-profile',
          component: () => import('@/views/ViewProfile.vue'),
          meta: {
            requiresAuth: true
          },
        },
        {
          path: '/adm_mgt',
          name: 'upload-data',
          component: () => import('@/views/modules/upload/UploadData.vue'),
          meta: {
            layout: 'full',
          },
        },

        ...adminRoute,
        ...dealerRoute,
        ...warrantyRoute,
        ...customerRoute,
      ]
    },
    {
      path: '',
      component: () => import('../layouts/full/LayoutFull.vue'),
      children: [
        {
          path: '/error-404',
          name: 'error-404',
          component: () => import('@/views/error/Error404.vue'),
          meta: {
            layout: 'full',
          },
        },
        {
          path: '/pages/login',
          name: 'login',
          component: () => import('@/views/login/Login.vue'),
          meta: {
            layout: 'full'
          },
        },
        {
          path: '/pages/login-qr',
          name: 'login-qr',
          component: () => import('@/views/login/LoginQR.vue'),
          meta: {
            layout: 'full'
          },
        },
        {
          path: '/pages/login-do/:doNumber',
          name: 'login-do',
          component: () => import('@/views/login/LoginQRV2.vue'),
          meta: {
            layout: 'full'
          },
        },
        {
          path: '/otp',
          name: 'otp',
          component: () => import('@/views/loginOtp.vue'),
          meta: {
            layout: 'full'
          },
        },
        
        {
          path: '/not-authorized',
          name: 'not-authorized',
          component: () => import('@/views/error/NotAuthorized.vue'),
          meta: {
            layout: 'full',
          },
        },
        {
          path: '/detail-warranty/:cert',
          name: 'detail-warranty',
          component: () => import('@/views/modules/warranty/detail-warranty-individual.vue'),
          props:true,
          meta: {
            layout: 'full'
          },
        },
        {
          path: '/script',
          name: 'script',
          component: () => import('@/views/ScriptRun.vue'),
          props:true,
          meta: {
            layout: 'full'
          },
        },
        // {
        //   path: '/second',
        //   name: 'Second-Pages',
        //   component: () => import('@/views/SecondPage.vue'),
        //   meta: {
        //     layout: 'full',
        //   },
        // },
        //Forgot Password
        {
          path: '/pages/forgot-password',
          name: 'forgot-password',
          component: () => import('@/views/forgot-password.vue'),
          meta: {
            layout: 'full',
            // requiresAuth: true,
          },
        },
        //Verify Password Token
        {
          path: '/pages/verify-password',
          name: 'verify-password',
          component: () => import('@/views/verify-token.vue'),
          meta: {
            layout: 'full',
            // requiresAuth: true,
          },
        },
        //detail-delivery with QR
        {
          path: '/warehouse/detail-delivery-qr/:from/:id',
          name: 'warehouse-detail-delivery-qr',
          component: () => import('@/views/modules/delivery-module/actions-detail/detail-delivery-qr.vue'),
          props: true,
          meta: {
            rule: 'editor',
            no_scroll: true,
            // requiresAuth: false,
          },
        },

        // {
        //   path: '/pages/register',
        //   name: 'register',
        //   component: () => import('@/views/login/Register.vue'),
        //   meta: {
        //     layout: 'full'
        //   },
        // },
      ],
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})


router.beforeEach((to, from, next) => {

  // console.log({status2FA})

  // Check Password if not yet updated, 2 seconds go to change password page
  // Maybe soon will be need set time to updated password regularly?
  // if (router.app.$session.exists()) {
  //   setTimeout(async() => {
  //     var getActiveUser = await store.getters['auth/getActiveUser'];
  
  //     var getLastUpdatedPassword = await getActiveUser ? getActiveUser.passwordUpdatedAt ? new Date(getActiveUser.passwordUpdatedAt).getTime() : undefined : undefined;

  //     await sleep(1500)
  
  //     if (!getLastUpdatedPassword) { 
  //       next({
  //         path: '/user/change-password',
  //       })
  //     }
  //   }, 1000)
  // } 
  

  if (to.matched.some(record => record.meta.requiresAuth)) {
    // if (store.getters['auth/getToken'] === '') {
    //   store.commit('auth/SET_PREV_AUTH_PAGE', to.path);

    //   next({
    //     path: '/pages/login',
    //   })
    // }

    // var path = to.path.split('/')

    // ================== by =====================

    // if (!router.app.$session.exists()) {
    //   console.log('masuk', store.getters['auth/getLoginOtp'].length)
    //   if (store.getters['auth/getLoginOtp'].length == 0) {
    //     next({
    //       path: '/pages/login',
    //     })
    //   }
    // }
    // else {
    //   next()
    // }

    // =================== by end ==================== 

    if (!router.app.$session.exists()) {
      next({
        path: '/pages/login'
      })
    } 
     else {
      next()
    }

    // =========================================

    // let status2FA = router.app.$session.get('status2FA')
    // if(status2FA === ''){
    //   next({path:'/second'})
    // }
  } else {
    next()
  }
})
export default router
