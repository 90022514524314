//temporary for call checkstock from store
import axios from '@/axios.js'

export default {    
  checkStock({ commit }, payload) {
    
    return new Promise((resolve, reject) => {
      
      //comment to enable check stock
      resolve(true);return;
      
      axios.post('sales-and-purchase/drcc/check-stock', payload).then((response) => {
        resolve(response.data.data)
      }).catch((e) => {
        console.log(e)
        reject(e)
      });
    });
  },
}
