import state from './channelState'
import mutations from './channelMutations'
import actions from './channelActions'
import getters from './channelGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
