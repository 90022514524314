import axios from '@/axios.js'

export default {
  getChannels({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/sales-and-purchase/drcc/channel').then((response) => {
        commit('GET_CHANNEL', response.data.data)
        resolve(response.data);
      
      }).catch((e) => {
        console.log(e)
        reject(e)
      })
    });
  },
  
  deleteChannel({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete('/sales-and-purchase/drcc/channel/' + id)
        .then(() => {
          commit('DELETE_CHANNEL', id)
          resolve()
        })
        .catch(err => reject(err))
    })
  },
  
  
}
