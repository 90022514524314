import state from './moduleMigrationState'
import mutations from './moduleMigrationMutations'
import actions from './moduleMigrationActions'
import getters from './moduleMigrationGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
