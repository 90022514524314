import state from './reportState'
import mutations from './reportMutations'
import actions from './reportActions'
import getters from './reportGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
