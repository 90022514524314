import state from './moduleLogState'
import mutations from './moduleLogMutations'
import actions from './moduleLogActions'
import getters from './moduleLogGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
