export default {
  dashboard: [],
  salesAdmin: [],
  doCreated: [],
  doProcessing: [],
  delivered: [],
  warranty: [],
  filterDashboard: [],
  dashboardPassword:{},
};
