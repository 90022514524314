import actions from './warrantyActions';
import mutations from './warrantyMutations';
import state from './warrantyState';
import getters from './warrantyGetters';

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters,
}
