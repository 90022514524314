export default {
  SET_OPTION_WARRANTY: (state,{warranty,minValue}) =>{
    var optionTemp = warranty.map((x)=>{
      var maxMonth = x.month;

      var array = []

      for(var i=minValue;i<=maxMonth;i++){
        array.push({
          value:i,
          text:i+" Months"
        })
      }

      return {
        field:x.recordStatus,
        options:array
      }
    })
    var allOption = {}
    for(var j=0;j<optionTemp.length;j++){
      allOption[optionTemp[j].field]=optionTemp[j].options
    }
    //console.info('all option ',allOption)
    state.optionWarranty = allOption
  },
  SET_INTERNAL_WARRANTY: (state, warranty) => {
    state.internalWarranty = warranty;
  },
  SET_EXTERNAL_WARRANTY: (state, warranty) => {
    state.externalWarranty = warranty;
  },
  SET_ALL_WARRANTY: (state, warranty) => {
    let finalData = []
    if(warranty.length !== 0){
      for (let i = 0; i < warranty.data.length; i++) {
        const element = warranty.data[i];
        if(element == null){
          console.log('ada null')
          let address = ''
          let address2 = ''
          let address3 =''
          let buildingName =''
          let buildingType =''
          // console.log(`element ke ${i}`,dataProcess[i])
          finalData.push({
          certNo : '',
          fullName:'',
          salutation:'',
          ref:'',
          address : `${address} ${address2} ${address3} ${buildingName}`,
          buildingType:buildingType,
          customerType: '',
          email : '',
          phone : '',
          postal :  '',
          registerType: '',
          installerName : '',
          installationDate: '',
          warrantyStatus : '',
          flagExpiry : '',
          purchaseDate:  '',
          companyName :  '',
          
          sapDo: '',
          dateDO:  '',
          dealerCode:  '',
          dealerName:  '',
  
          pcode : '',
          phlvl1 : '',
          generalWarrantyDuration : '',
          compressorWarrantyDuration : '',
          residentialWarrantyEndDate : '',
          generalWarrantyEndDate : '',
          compressorWarrantyEndDate:'',
          invoiceDate : '',
          industryType : '',
  
          barcode:'',
          name:'',
          topDate: '', 
          itemModel: '',
          developer: '',
          serial:'',
          createdAt: ''
  
        })
          continue
        }
        let address = element.address?element.address:''
        let address2 = element.address2?element.address2:''
        let address3 =element.address3?element.address3:''
        let buildingName =element.buildingName?element.buildingName:''
        let buildingType =element.buildingType?element.buildingType:''
        
        finalData.push({
          id:element.id,
          certNo : element.certNo,
          fullName:element.fullName,
          salutation:element.salutation,
          
          address : `${address} ${address2} ${address3} ${buildingName}`,
          buildingType:buildingType,
          customerType: element.customerType,
          email : element.email,
          phone : element.phone,
          postal : element.postal,
          registerType: element.registerType,
          installerName : element.installerName,
          installationDate: element.installationDate,
          warrantyStatus : element.warrantyStatus,
          flagExpiry : element.flagExpiry,
          purchaseDate: element.purchaseDate,
          companyName : element.companyName,
          
          sapDo: element.sapDo,
          dateDO: element.dateDO,
          dealerCode: element.dealerCode,
          dealerName: element.dealerName,
  
          pcode : element.pcode,
          phlvl1 : element.phlvl1,
          generalWarrantyDuration : element.generalWarrantyDuration,
          compressorWarrantyDuration : element.compressorWarrantyDuration,
          residentialWarrantyEndDate : element.residentialWarrantyEndDate,
          generalWarrantyEndDate : element.generalWarrantyEndDate,
          compressorWarrantyEndDate:element.compressorWarrantyEndDate,
          invoiceDate : element.invoiceDate,
          industryType : element.industryType,
          ref:element.ref,
  
          barcode:element.barcode,
          name:element.name,
          topDate:element.topDate,
          itemModel:element.itemModel,
          developer:element.developer,
          serial:element.serial,
          createdAt:element.createdAt,
          remarks: element.remarks,
        })
        
      }  
      state.allWarranty = finalData;
    }else{
      state.allWarranty = warranty
    }
    
  },
  SET_WARRANTY: (state, warranty) => {
    state.warranty = warranty;
  },
};
