import state from './purchaseState'
import mutations from './purchaseMutations'
import actions from './purchaseActions'
import getters from './purchaseGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
