export default [
    // {
    //     path: '/warranty/paperless',
    //     name: 'warranty-list-paperless',
    //     component: () => import('@/views/modules/warranty/paperless-warranty-list.vue'),
    //     props: true,
    //     meta: {
    //         breadcrumb: [
    //         { text: 'Paperless Registration', active: true },
    //         ],
    //         rule: 'editor',
    //         no_scroll: true,
    //         requiresAuth: true,
    //     },
    // },
    // {
    //     path: '/warranty/paperless/detail/:id',
    //     name: 'warranty-detail-paperless',
    //     component: () => import('@/views/modules/warranty/paperless-detail-warranty.vue'),
    //     props: true,
    //     meta: {
    //         breadcrumb: [
    //         { text: 'Paperless Registration', to: '/warranty/paperless' },
    //         { text: 'Detail Registration', active: true },
    //         ],
    //         rule: 'editor',
    //         no_scroll: true,
    //         requiresAuth: true,
    //     },
    // },
    {
        path: '/warranty/inventories',
        name: 'warranty-inventories-all',
        component: () => import('@/views/modules/warranty/all-inventory.vue'),
        props: true,
        meta: {
          breadcrumb: [
            { text: 'Inventory', active: true }
          ],
          rule: 'editor',
          no_scroll: true,
          requiresAuth: true,
        },
    },
    // {
    //     path: '/warranty/inventory/detail/:id',
    //     name: 'warranty-inventory-detail',
    //     component: () => import('@/views/modules/admin/inventory/detail-inventory-item.vue'),
    //     props: true,
    //     meta: {
    //       breadcrumb: [
    //         { text: 'Inventory', active: true }
    //       ],
    //       rule: 'editor',
    //       no_scroll: true,
    //       requiresAuth: true,
    //     },
    // },
];