import axios from '@/axios.js'

export default {
  getItems({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios.get('/sales-and-purchase/drcc/items', {
        params: params
      }).then((response) => {
        commit('SET_ITEMS', response.data.data)
        resolve(response.data);
      
      }).catch((e) => {
        console.log(e)
        reject(e)
      })
    });
  },
  //THIS IS FOR MASTERDATA/ITEM-BUNDLE
  addItemBundle({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('sales-and-purchase/drcc/item/bundle', payload).then((response) => {
        resolve(response.data.data)
      }).catch((e) => {
        console.log(e)
        reject(e)
      });
    });
  },
  listItemBundle({ commit },params) {
    return new Promise((resolve, reject) => {
      axios.get('sales-and-purchase/drcc/item/bundle', {
        params: params
      }).then((response) => {
        commit('SET_ITEM_BUNDLE', response.data.data)
        resolve(response.data)
      }).catch((e) => {
        console.log(e)
        reject(e)
      });
      
    });
  },
  deleteItemBundle({ commit }, bundleId) {
    return new Promise((resolve, reject) => {
      axios.delete(`sales-and-purchase/drcc/item/bundle/${bundleId}`).then((response) => {
        resolve(response.data.data)
      }).catch((e) => {
        console.log(e)
        reject(e)
      });
    });
  },
  updateItemBundle({ commit }, payload) {
    let { id } = payload;
    return new Promise((resolve, reject) => {
      axios.put(`sales-and-purchase/drcc/item/bundle/${id}`, payload).then((response) => {
        resolve(response.data.data)
      }).catch((e) => {
        console.log(e)
        reject(e)
      });
    });
  },
  detailBundle({ commit }, bundleId) {
    return new Promise((resolve, reject) => {
      axios.get(`sales-and-purchase/drcc/item/bundle/${bundleId}`).then((response) => {
        resolve(response.data.data)
      }).catch((e) => {
        console.log(e)
        reject(e)
      });
    });
  },
  //END--THIS IS FOR MASTERDATA/ITEM-BUNDLE

  //This is for list item depends on the dealer selected or the dealer currently logged in
  getDealerItemToOrder(context, search) { 
    return new Promise((resolve, reject) => {
      axios.get('sales-and-purchase/drcc/order-items', {
        params: search ,
      }).then((response) => {
        resolve(response.data.data);
      }).catch((e) => {console.log(e);reject(e)});
    });
  }
  
}
