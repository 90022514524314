import axios from '@/axios.js';

export default {
  getAllInventories({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios.get(`sales-and-purchase/inventory/search`, { params: { ...params } }).then((response) => {
        commit('SET_INVENTORIES', response.data.data);
        resolve(response.data)
      }).catch((e) => {
        console.log(e);
        reject(e);
      })
    })
  },
  getAllInventoriesDealer({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios.get(`sales-and-purchase/inventory-dealer/search`, { params: { ...params } }).then((response) => {
        commit('SET_INVENTORIES_DEALER', response.data.data);

        //map is required for bulk print
        response.data.data = response.data.data.map((x)=>{
          return {...x,selected:false}
        })
        resolve(response.data)
      }).catch((e) => {
        console.log(e);
        reject(e);
      })
    })
  }, 
  updateInventory({ commit }, {id, payload}) {
    console.log("payload",payload);
    console.log("id", id);
    return new Promise((resolve, reject) => {
      axios.put(`sales-and-purchase/drcc/update-item/${id}`, payload).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        console.log(e)
        reject(e)
      });
    });
  },
  deleteInventory({ commit }, {id}) {
    console.log("id", id);
    return new Promise((resolve, reject) => {
      axios.delete(`sales-and-purchase/inventory/delete-item/${id}`).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        console.log(e)
        reject(e)
      });
    });
  },
}
