import state from './itemState'
import mutations from './itemMutations'
import actions from './itemActions'
import getters from './itemGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
