import axios from '@/axios.js'

export default {
  fetchRole: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/auth-drcc/roles')
        .then(res => {
          commit('FETCH_ROLE', res.data)
          resolve()
        })
        .catch(err => reject(err))
    })
  },
  addRole: ({commit}, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/auth-drcc/roles', payload)
        .then(res => {
          commit('ADD_ROLE', res.data)
          resolve()
        })
        .catch(err => reject(err))
    })
  },
  updateRole: ({ commit }, { id, data }) => {
    return new Promise((resolve, reject) => {
      axios
        .put('/auth-drcc/roles/' + id, data)
        .then(res => {
          commit('UPDATE_ROLE', res.data)
          resolve()
        })
        .catch(err => reject(err))
    })
  },
  deleteRole: ({ commit }, { id }) => {
    return new Promise((resolve, reject) => {
      axios
        .delete('/auth-drcc/roles/' + id)
        .then(() => {
          commit('DELETE_ROLE', id)
          resolve()
        })
        .catch(err => reject(err))
    })
  },
  getReport: ({commit}) =>{
    return new Promise((resolve, reject) => {
      axios
        .get('/auth-drcc/roles-report')
        .then((res) => {
          //commit('FETCH_USER', res.data.data)
          resolve(res.data)
        })
        .catch(err => reject(err))
    })
  },
}