import axios from '@/axios.js'

export default {
  fetchGST: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get('sales-and-purchase/drcc/gst')
        .then(res => {
          console.log({res})
          commit('FETCH_GST', res.data.data)
          resolve()
        })
        .catch(err => reject(err))
    })
  },
  addGST: ({commit}, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/sales-and-purchase/drcc/gst', payload)
        .then(res => {
          commit('ADD_GST', res.data.data)
          resolve()
        })
        .catch(err => reject(err))
    })
  },
  // updateGST: ({ commit }, { id, data }) => {
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .put('sales-and-purchase/drcc/gst/' + id, data)
  //       .then(res => {
  //         commit('UPDATE_GST', res.data)
  //         resolve()
  //       })
  //       .catch(err => reject(err))
  //   })
  // },
  updateGST( context,payload) {
    let { _id } = payload;
    return axios.put(`sales-and-purchase/drcc/gst/${_id}`, payload)
    .then((response) => {
      // console.log(response)
      return  response.data.data ;
    }).catch((e) => {
      console.log(e);
      throw e
    })
  },
  deleteGST: ({ commit }, { id }) => {
    return new Promise((resolve, reject) => {
      axios
        .delete('/sales-and-purchase/drcc/gst/' + id)
        .then(() => {
          commit('DELETE_GST', id)
          resolve()
        })
        .catch(err => reject(err))
    })
  },
  getGST(context, id) {
    return new Promise((resolve, reject) => {
      axios.get(`sales-and-purchase/drcc/gst/${id}`).then((response) => {
        resolve(response.data.data)
      }).catch((e) => {
        
        console.log(e);
        reject(e)
      });
    })
  },
  getCurrentGST: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get('sales-and-purchase/drcc/gst/calculation-date')
        .then(res => {
          // console.log({res})
          commit('FETCH_CURRENT_GST', res.data.data)
          resolve()
        })
        .catch(err => reject(err))
    })
  },
}