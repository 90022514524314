import state from './deliveryState'
import mutations from './deliveryMutations'
import actions from './deliveryActions'
import getters from './deliveryGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
