import axios from '@/axios.js'

export default {
  getAllDealers: ({ commit }) => {
    return axios.get('/sales-and-purchase/drcc/customer').then((response) => {
      commit('GET_DEALERS', response.data.data)
    });
  },
  getDealerWithQuery({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios.get('/sales-and-purchase/drcc/customer', {
        params: params
      }).then((response) => {
        commit('GET_DEALERS', response.data.data)
        resolve(response.data);
      }).catch((e) => {
        console.log(e)
        reject(e);
      })
    });
  },
  detailDealer({ commit }, idDealer) {
    return new Promise((resolve, reject) => {
      axios.get(`sales-and-purchase/drcc/customer/${idDealer}`).then((response) => {
        resolve(response.data.data)
      }).catch((e) => {
        console.log(e)
        reject(e)
      });
    });
  },
  
  //this is for update from user only. assigned id of user who have user account
  updateDealer({ commit }, payload) { 
    let { _id } = payload;
    return new Promise((resolve, reject) => {

      axios.put(`sales-and-purchase/drcc/customer/${_id}`, payload).then((response) => {
        resolve(response.data.data)
      }).catch((e) => {
        console.log(e)
        reject(e)
      })
    })
  },
  //this is for update from user only. assigned id of user who have user account
  updateDealerV2({ commit }, payload) { 
    //expected payload (userID,dealerID)
    return new Promise((resolve, reject) => {

      axios.put(`sales-and-purchase/drcc/dealer`, payload).then((response) => {
        resolve(response.data.data)
      }).catch((e) => {
        console.log(e)
        reject(e)
      })
    })
  },
  //this is for call API check current dealer information
  getCurrentDealer: ({ commit }) => {
    return new Promise((resolve,reject) => {
      axios.get('/sales-and-purchase/getCurrentDealer').then((response) => {
        commit('GET_CURRENT_DEALERS', response.data.data)
        resolve(response.data.data)
      }).catch((e)=>{
        console.log({e})
        reject(e)
      });
    }) 
  },
}
