export default [
  {
    path: '/dashboard/dealer',
    name: 'dashboard-dealer',
    component: () => import('@/views/modules/dealer/dashboard/dashboard.vue'),
    props: true,
    meta: {
      rule: 'editor',
      no_scroll: true,
      // requiresAuth: true,
    },
  },
  {
    path: '/dashboard/sales-admin',
    name: 'dashboard-sales-admin',
    component: () => import('@/views/modules/dealer/dashboard/dashboard-sales-admin.vue'),
    props: true,
    meta: {
      rule: 'editor',
      no_scroll: true,
      // requiresAuth: true,
    },
  },
  // {dashboard-sales-admin
  //   path: '/dashboard/docreated',
  //   name: 'dashboard',
  //   component: () => import('@/views/modules/dealer/dashboard/DOcreated.vue'),
  //   props: true,
  //   meta: {
  //     rule: 'editor',
  //     no_scroll: true,
  //     // requiresAuth: true,
  //   },
  // },
  // {
  //   path: '/dashboard/doprocessing',
  //   name: 'dashboard',
  //   component: () => import('@/views/modules/dealer/dashboard/DOprocessing.vue'),
  //   props: true,
  //   meta: {
  //     rule: 'editor',
  //     no_scroll: true,
  //     // requiresAuth: true,
  //   },
  // },
  // {
  //   path: '/dashboard/warranty',
  //   name: 'dashboard',
  //   component: () => import('@/views/modules/dealer/dashboard/warranty.vue'),
  //   props: true,
  //   meta: {
  //     rule: 'editor',
  //     no_scroll: true,
  //     // requiresAuth: true,
  //   },
  // },
  // {
  //   path: '/dashboard/itemdelivered',
  //   name: 'dashboard',
  //   component: () => import('@/views/modules/dealer/dashboard/itemDelivered.vue'),
  //   props: true,
  //   meta: {
  //     rule: 'editor',
  //     no_scroll: true,
  //     // requiresAuth: true,
  //   },
  // },
  // {
  //   path: '/order',
  //   name: 'order',
  //   component: () => import('@/views/modules/dealer/purchase/purchase.vue'),
  //   props: true,
  //   meta: {
  //     rule: "editor",
  //     no_scroll: true,
  //     // requiresAuth: true,
  //   },
  // },
  {
    path: "/dashboard/docreated/detail",
    name: "dashboard-created",
    component: () => import("@/views/modules/dealer/dashboard/dashboard-created.vue"),
    props: true,
    meta: {
      rule: "editor",
      no_scroll: true,
      // requiresAuth: true,
    },
  },
  {
    path: "/dashboard/doprocessing/detail",
    name: "dashboard-processing",
    component: () =>
      import("@/views/modules/dealer/dashboard/dashboard-processing.vue"),
    props: true,
    meta: {
      rule: "editor",
      no_scroll: true,
      // requiresAuth: true,
    },
  },
  {
    path: "/dashboard/doprocessing/detail/:id",
    name: "dashboard-detail-processing",
    component: () =>
      import("@/views/modules/dealer/dashboard/view-processing.vue"),
    props: true,
    meta: {
      rule: "editor",
      no_scroll: true,
      // requiresAuth: true,
    },
  },
  {
    path: "/dashboard/docreated/detail/:id",
    name: "dashboard-detail-created",
    component: () =>
      import("@/views/modules/dealer/dashboard/view-created.vue"),
    props: true,
    meta: {
      rule: "editor",
      no_scroll: true,
      // requiresAuth: true,
    },
  },
  {
    path: "/dashboard/warranty/detail",
    name: "dashboard-warranty",
    component: () => import("@/views/modules/dealer/dashboard/dashboard-registered.vue"),
    props: true,
    meta: {
      rule: "editor",
      no_scroll: true,
      // requiresAuth: true,
    },
  },
  {
    path: "/dashboard/itemdelivered/detail",
    name: "dashboard-delivered",
    component: () =>
      import("@/views/modules/dealer/dashboard/dashboard-delivered.vue"),
    props: true,
    meta: {
      rule: "editor",
      no_scroll: true,
      // requiresAuth: true,
    },
  },
  {
    path: "/order",
    name: "order",
    component: () => import("@/views/modules/dealer/purchase/purchase.vue"),
    props: true,
    meta: {
      breadcrumb: [{ text: "Purchase Order List", active: true }],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/po-fail",
    name: "po-fail",
    component: () => import("@/views/modules/dealer/purchase/list-po-fail.vue"),
    props: true,
    meta: {
      breadcrumb: [{ text: "PO Fail List", active: true }],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/order/add",
    name: "order-add",
    component: () => import("@/views/modules/dealer/purchase/add-purchase.vue"),
    props: true,
    meta: {
      breadcrumb: [
        { text: "Purchase Order List", to: "/order" },
        { text: "Add Purchase Order", active: true },
      ],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/order/detail/:id",
    name: "order-detail",
    component: () =>
      import("@/views/modules/dealer/purchase/detail-purchase.vue"),
    props: true,
    meta: {
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/order/draft/edit/:idSales",
    component: () =>
      import("@/views/modules/dealer/purchase/edit-purchase-draft.vue"),
    name: "order-edit-draft",
    props: true,
    meta: {
      breadcrumb: [
        { text: "Purchase Order List", to: "/order" },
        { text: "Edit Purchase Draft", active: true },
      ],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },

  // Sales Route
  {
    path: "/sales",
    name: "view-sales-order",
    component: () => import("@/views/modules/dealer/sales/sales.vue"),
    props: true,
    meta: {
      breadcrumb: [{ text: "Sales Order List", active: true }],
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
  {
    path: "/sales/detail/:idSales",
    name: "sales-order-detail",
    component: () => import("@/views/modules/dealer/sales/detail-sale.vue"),
    props: true,
    meta: {
      rule: "editor",
      no_scroll: true,
      requiresAuth: true,
    },
  },
];
