import axios from "@/axios.js";

export default {
  fetchPendingReview: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get("sales-and-purchase/pending-itemWarranty")
        .then((res) => {
          console.log({ res });
          let sorted = res.data.data.sort((a, b) => b.ID.localeCompare(a.ID));
          commit("FETCH_PENDINGREVIEW", sorted);
          resolve();
        })
        .catch((err) => reject(err));
    });
  },
  addPendingReview: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/sales-and-purchase/pending-itemWarranty", payload)
        .then((res) => {
          commit("ADD_PENDINGREVIEW", res.data.data);
          resolve();
        })
        .catch((err) => reject(err));
    });
  },
  updatePendingReview(context, payload) {
    let { _id } = payload;
    return axios
      .put(`sales-and-purchase/pending-itemWarranty/${_id}`, payload)
      .then((response) => {
        // console.log(response)
        return response.data.data;
      })
      .catch((e) => {
        console.log(e);
        throw e;
      });
  },

  getPendingReview(context, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`sales-and-purchase/pending-itemWarranty/${id}`)
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
        });
    });
  },
};
