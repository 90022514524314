export default {
    addFilter({ commit }, name, filterData) {
        commit('ADD_FILTER', {
            name: name,
            data: filterData,
        });
    },
    getFilter({ state, commit }, filterName) {
        if (state.filterContainer[filterName] === undefined) {
            commit('ADD_FILTER', {
                name: filterName,
                data: {},
            });
        }

        return {};
    },
    updateFilter({ commit }, filterData) {
        let { name, data } = filterData;

        if (name === undefined) {
            console.log('Forget To Add Filter Name ?');
            return;
        }

        commit('UPDATE_FILTER', {
            name: name,
            data: data,
        });
    }
}