export default {
  GET_DO : function (state, data){ //mutation for callback DO
    state.deliveryOrders = data;
  },
  GET_DELIVERY_ID:(state,payload)=>{    
    state.deliveryById = payload;
  },
  GET_MASS_DO_UPDATE : function (state, data){ //mutation for callback DO
    state.massDeliveryorders = data;
  },
}
