export default {
  FETCH_PENDINGREVIEW: (state, payload) => {
    state.pendingReview = payload
  },
  ADD_PENDINGREVIEW: (state, payload) => {
    state.pendingReview.push(payload)
  },
  UPDATE_PENDINGREVIEW: (state, payload) => {
    let pendingReview = state.pendingReview.find(u => u.id === payload.id)
    for (let [key, value] of Object.entries(payload)) {
      if (pendingReview[key] && pendingReview[key] !== value) {
        pendingReview[key] = value
      }
    }
  },
}