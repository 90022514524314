import axios from '@/axios.js'

export default {
  fetchTermCondition: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get('sales-and-purchase/drcc/term')
        .then(res => {
          console.log({res})
          commit('FETCH_TERMCONDITION', res.data.data)
          resolve()
        })
        .catch(err => reject(err))
    })
  },
  addTermCondition: ({commit}, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/sales-and-purchase/drcc/term', payload)
        .then(res => {
          commit('ADD_TERMCONDITION', res.data.data)
          resolve()
        })
        .catch(err => reject(err))
    })
  },
  updateTermCondition( context,payload) {
    let { _id } = payload;
    return axios.put(`sales-and-purchase/drcc/term/${_id}`, payload).then((response) => {
      // console.log(response)
      return  response.data.data ;
    }).catch((e) => {
      console.log(e);
      throw e
    })
  },
  
  getTermCondition(context, id) {
    return new Promise((resolve, reject) => {
      axios.get(`sales-and-purchase/drcc/TermCondition/${id}`).then((response) => {
        resolve(response.data.data)
      }).catch((e) => {
        
        console.log(e);
        reject(e)
      });
    })
  },
}