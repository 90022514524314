import axios from '@/axios.js'

export default {
  fetchLog: ({ commit },params) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/auth/log',{ params: { ...params }})
        .then(res => {
          commit('GET_LOG', res.data.data)
          resolve(response.data)
        })
        .catch(err => reject(err))
    })
  },
  addLog: ({commit}, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/auth/log', payload)
        .then(res => {
          commit('ADD_LOG', res.data)
          resolve()
        })
        .catch(err => reject(err))
    })
  }
}