
import router from '@/router/index.js'

const dateFormat = function (date) {
  var time = new Date(date);
  // console.info ('Date time ',time.toLocaleString())
  // return new Date(date).toLocaleString('id-ID', { year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' });
  return time.toLocaleString('en-SG', {timeZone: 'Asia/Singapore', year: 'numeric', month: '2-digit', day: '2-digit' });
}
const dateFormatFull = function (date) {
  // return new Date(date).toLocaleString('id-ID', { year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' });
  const timeData=new Date(date);
  console.info ('Date time Full',timeData.toLocaleString())
  return timeData.toLocaleString('id-ID', { year: 'numeric', month: '2-digit', day: '2-digit' })+", "+timeData.toLocaleTimeString();
}
const dateFormatFullWithSecond = function (date) {
  // return new Date(date).toLocaleString('id-ID', { year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' });
  const timeData=new Date(date);
  console.info ('Date time Full',timeData.toLocaleString())
  return timeData.toLocaleString('id-ID', { year: 'numeric', month: '2-digit', day: '2-digit',hour:'numeric',minute:'numeric',second: 'numeric' })+", "+timeData.toLocaleTimeString();
}

const userAccess = (submenu, menu) => {
  let permission = JSON.parse(router.app.$session.get('permission'));
  // console.log({permission})

  //==========================
  // let expired = (router.app.$session.get('expiry'));
  // console.log('expired',expired);

  // const now = new Date();
  //==========================

  //check permission
  let permissionTemp= permission[menu.toLowerCase()];
  if(permissionTemp===undefined | !permissionTemp){
    //if permission is not found, return false
    //user will automatically logged out with warning
    //GETTING PERMISSION DATA ERROR - Please login again
    return false
  }

  //==============================
  // if(expired < now){
  //   console.log('expired < now',expired < now)
  //   router.app.$session.clear()
  //   router.app.$session.destroy()
  //   window.location.reload()
  // }
  //==============================

  if (permission.allAccess) {
    return {
      add: true,
      edit: true,
      delete: true,
    }
  } else {
    return permissionTemp.filter(el => {
      return el.name.toLowerCase() === submenu.toLowerCase()
    })[0]
  }
}

const viewAccessLogin = () => {
  let permission = JSON.parse(router.app.$session.get('permission'));

  //check permission
  let permissionTemp= permission[Object.keys(permission)[0]]
  if(permissionTemp===undefined | !permissionTemp){
    //if permission is not found, return false
    //user will automatically logged out with warning
    //GETTING PERMISSION DATA ERROR - Please login again
    return false
  }
  if (permission.allAccess) {
    return ['dashboard', 'item', 'role', 'user']
  } else {
    return permissionTemp.filter(el => {
      return el.view;
    }).map(el => {
      return `${el.name.replace(/\s+/g, '-').toLowerCase()}`;
    })
  }
}

const viewAccess = (menus) => {
  let permission = JSON.parse(router.app.$session.get('permission'));

  //check permission
  let permissionTemp= permission[menus]
  if(permissionTemp===undefined | !permissionTemp){
    //if permission is not found, return false
    //user will automatically logged out with warning
    //GETTING PERMISSION DATA ERROR - Please login again
    return false
  }

  if (permission.allAccess) {
    return ['dashboard', 'item', 'role', 'user']
  } else {
    return permissionTemp.filter(el => {
      return el.view;
    }).map(el => {
      return `${el.name.replace(/\s+/g, '-').toLowerCase()}`;
    })
  }
}
const sleep = (ms) =>{
    return new Promise(resolve => setTimeout(resolve, ms));
}

const pushTo = (route )=>{
  this.$router.push(route)

}
const viewPermission = (menu, nameMenu = '', navMenu = [], viewAuth) => {


  let findMenu = menu.find(el => { return el.title === `${nameMenu}` })
  let child = []
  let itemNav = {}

  if (findMenu !== undefined && viewAuth.length > 0) {
    if (findMenu.children) {
      for (let el of findMenu.children) {
        if (viewAuth.includes(el.route.replace(/\s+/g, '-').toLowerCase())) {
          child.push(el)
        }
      }
      
      itemNav = {
        title: findMenu.title,
        icon: findMenu.icon ? findMenu.icon : 'UsersIcon',
        children: child
      }
      Object.keys(findMenu).length === 0 ? '' : navMenu.push(itemNav)
    } else {
      
        if (viewAuth.includes(findMenu.route.replace(/\s+/g, '-').toLowerCase())) {
          navMenu.push(findMenu)
        }
      
    }
  }
}
const printLayouting = (downloadContent)=>{
  let content = []
  
  for(let i=0; i<downloadContent.length; i++){
    if(downloadContent[i].type === 'rowInputOneColumn'){
      downloadContent[i].data.forEach( el=> {
        content.push( {
          columns: [            
            { text: `${el.split(':')[0]}`, fontSize: 11, bold: true },
            { text: `: ${el.split(':')[1]}`, fontSize: 11, },
            {}
          ], margin: [0, 0, 0, 5]
        })
      })
    }
    if(downloadContent[i].type === 'table'){
      let widths = []
      let header = []
      let dataTable = []
      downloadContent[i].data.header.forEach( el=> {
        widths.push(
          `${100/downloadContent[i].data.header.length}%`
        )
        header.push(                  
          {text: `${el}`, style: 'tableHeader', alignment: 'center'}, 
        )
      })
      downloadContent[i].data.dataTable.forEach( (el, index)=> {
        let row = []
        downloadContent[i].data.valueTable.forEach ( (e, i) => {
          let key = []
          e.split('.').forEach( doc => {
            key.push([`${doc}`])
          })
          let data = el
          key.forEach(doc=>{
            data = data[doc]
          })
          
          row.push(            
            {text: `${data}`, style: 'normal', alignment: 'center'}, 
          )
        })
        
        dataTable.push(row)
      })

      content.push(
        {
          style: 'tableCommon',
          table: {
            widths: downloadContent[i].data.widths?downloadContent[i].data.widths:widths,
            body: [
               header,
               ...dataTable
            ]
          },
          layout: downloadContent[i].data.noBorder?'noBorders':''
        },
      )
    }    
    if(downloadContent[i].type === 'image'){
      content.push({...downloadContent[i].data, margin: [0, 0, 0, 10],})
    }
    if(downloadContent[i].type === 'customerInfo'){
      content.push(
        {
          columnt
        }
      )
    }
    if(downloadContent[i].type === 'topPart'){
      content.push(
        {
          columns: [
            {
              text: [
                { text: `${downloadContent[i].data.greeting}`, fontSize: 13 },
                {
                  text: `${downloadContent[i].data.name?downloadContent[i].data.name:''}`,
                  fontSize: 12,
                  bold: true,
                },
              ],
              alignment: "left",
              margin: [0, 0, 0, 10],
            },
            {
              text: `${downloadContent[i].data.additionalInfo.split(':')[0]}`,
              fontSize: 13 ,                
              alignment: "right",
              margin: [0, 0, 0, 10],
            },
          ],
        },
        {
          columns: [
            {
            },
            {
              text: `${downloadContent[i].data.additionalInfo.split(':')[1]}`,
              fontSize: 11 ,                
              alignment: "right",
              bold:true,
              margin: [0, 0, 0, 10],
            },
          ],
        },
        )
    }
    if(downloadContent[i].type === 'text'){
      content.push(
        {
          text: `${downloadContent[i].data}`,
          fontSize: 11 ,    
          margin: downloadContent[i].margin,
        },
      )
    }
    if(downloadContent[i].type === 'text-big'){
      content.push(
        {
          text: `${downloadContent[i].data}`,
          fontSize: 14 ,
          bold: true,    
          margin: downloadContent[i].margin,
        },
      )
    }
  }

  var docDefinition = {
    footer: function (currentPage, pageCount) {
      return {
        text: `Page ${currentPage} of ${pageCount}`,
        alignment: "center",
        fontSize: 10,
        margin: [0, 5, 0, 0],
      };
    },
    content: content,
    styles: {
      tableCommon: {
        margin: [0, 10, 0, 10],
        fontSize: 11,
      },
      tableHeader: {
        bold: true,
        fontSize: 11,
        bod: true,
        alignment: "center",
      },
    },
    
    defaultStyle: {
      font: "Calibri",
    },
    
  };
  console.log('docDefinition ',docDefinition)
  return docDefinition
}
const downloadByPDFMake = (downloadContent, fileName) => {
  var pdfMake = require("pdfmake/build/pdfmake.js");
  var pdfFonts = require("./customVfs.js");
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  
  var docDefinition =printLayouting(downloadContent)
  
  pdfMake.fonts = {
    Calibri: {
      normal: "calibri.ttf",
      bold: "calibrib.ttf",
      italics: "calibrii.ttf",
      bolditalics: "calibril.ttf",
    },
  };
  
  
  //un comment below to have browser open printing display
  //pdfMake.createPdf(docDefinition).print();


  
  pdfMake.createPdf(docDefinition).download(`${fileName}`);
}
const convertPDFtoBase64 = (downloadContent)=>{
  var pdfMake = require("pdfmake/build/pdfmake.js");
  /*
  var pdfFonts = require("./customVfs.js");
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  
  
  pdfMake.fonts = {
    Calibri: {
      normal: "calibri.ttf",
      bold: "calibrib.ttf",
      italics: "calibrii.ttf",
      bolditalics: "calibril.ttf",
    },
  };
  */
  //var docDefinition =printLayouting(downloadContent)
  
  return new Promise((resolve,reject)=>{
    pdfMake.createPdf(downloadContent).getBase64((data)=>{
      if(!data){
        reject('Error getting Base 64 PDF');
      }
      resolve(data)
    })
  })
  

  
}
export {
  dateFormat,
  dateFormatFull,
  dateFormatFullWithSecond,
  userAccess,
  viewAccess,
  viewPermission,
  viewAccessLogin,
  sleep,
  pushTo,
  downloadByPDFMake,
  convertPDFtoBase64
} 