import actions from './moduleActions';
import state from './moduleState';
import mutations from './moduleMutations';
import getters from './moduleGetters';

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}