export default [
    {
      path: '/customer/warranty',
      name: 'customer-warranty',
      component: () => import('@/views/modules/customer/list-warranty-individual.vue'),
      props: true,
      meta: {
          breadcrumb: [
          { text: 'List Warranty Registration', active: true },
          ],
          rule: 'editor',
          no_scroll: true,
          requiresAuth: true,
      },
  },
  {
    path: '/customer/warranty/detail/:id',
    name: 'customer-warranty-detail',
    component: () => import('@/views/modules/customer/detail-warranty-individual.vue'),
    props: true,
    meta: {
        breadcrumb: [
        { text: 'List Warranty Registration', to: '/warranty/paperless/list-individual' },
        { text: 'Detail Registration', active: true },
        ],
        rule: 'editor',
        no_scroll: true,
        requiresAuth: true,
    },
},
];