// axios trigger
import axios from 'axios'
// Development
// const baseURL = 'https://phibasesuite.dev/api/v1'
export default axios.create({
  // baseURL
  baseURL: ``,
  // baseURL: `https://rsp.appdemo.sg/api/v1`,
  // You can add your headers here
})
