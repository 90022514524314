import state from './moduleGstState'
import mutations from './moduleGstMutations'
import actions from './moduleGstActions'
import getters from './moduleGstGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
