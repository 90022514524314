export default {
  filterInventory: (state) => {
    return state.inventories.map((item) => {
      let customer = item.customer;
      let skip = item.skip;

      if (customer === undefined || customer === null ) {
        return Object.assign(item, { customer: {type: 'Individual', nameFirst: '', nameLast: ''}});
      }
      
      return item;
    });
  },
  filterInventoryDealer: (state) => {
    return state.inventoriesDealer.map((item) => {
      let customer = item.customer;
      let skip = item.skip;

      if (customer === undefined || customer === null ) {
        return Object.assign(item, { customer: {type: 'Individual', nameFirst: '', nameLast: ''}});
      }
      
      return item;
    });
  }
}
