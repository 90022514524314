import axios from '@/axios.js'

export default {
  removePoFail({ commit }, payload) {
    //payload is id
    return new Promise((resolve, reject) => {
      axios.delete('/sales-and-purchase/failpurchase/' + payload).then((response) => {
        resolve(response.data);
      }).catch((e) => {
        console.error(e);
        reject(e)
      })
    })
  },
  getPurchase({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get('/sales-and-purchase/purchase', {
        params: payload
      }).then((response) => {
        commit('SET_PURCHASE', response.data.data)
        resolve(response.data);
      }).catch((e) => {
        console.error(e);
        reject(e)
      })
    })
  },
  getFailPurchase({ commit }, payload) {
    return new Promise((resolve, reject) => {
      console.log("payload", payload);
      
      axios.get('/sales-and-purchase/failpurchase', {
        params: payload
      }).then((response) => {
        console.log("response", response);
        
        commit('SET_FAILPURCHASE', response.data.data)
        resolve(response.data);
      }).catch((e) => {
        console.error(e);
        reject(e)
      })
    })
  },
  savePurchaseDraft ({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.post('sales-and-purchase/purchase/draft', data).then((response) => {
        resolve(response.data.data)
      }).catch((err) => {
        console.log(err)
        reject(err)
      })
    })
    
  },
  updatePurchaseDraft : ({ commit }, { id, data }) => {
    return new Promise((resolve, reject) => {
      axios
        .put('sales-and-purchase/purchase/draft/' + id, data)
        .then(res => {         
          resolve(res.data)
        })
        .catch(err => reject(err))
    })
  },
  
  detailPurchase:({ commit }, { id })=>{
    return new Promise((resolve, reject) => {
      axios
        .get('/sales-and-purchase/purchase/detail/'+id)
        .then(res => {
          commit('GET_PURCHASE_ID',res.data.data)
          resolve(res.data.data);
        })
        .catch(err => {
          console.error(err);
          //alert(err);
          reject(err);
        });
    })
    
  },
  deletePurchase: ({ commit }, { id }) => {
    return new Promise((resolve, reject) => {
      axios
        .delete('/sales-and-purchase/purchase/' + id)
        .then(() => {
          commit('DELETE_PURCHASE', id)
          resolve()
        })
        .catch(err => {
          //alert(err.message)
          reject(err)
        })
    })
  },
}
