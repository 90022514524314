import state from './dealerState'
import mutations from './dealerMutations'
import actions from './dealerActions'
import getters from './dealerGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
