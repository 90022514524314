import state from './saleState'
import mutations from './saleMutations'
import actions from './saleActions'
import getters from './saleGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
