import axios from '@/axios'
import router  from '@/router/index'

export default {
  register: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/auth/register', Object.assign(payload, { app: 'DRCC' }))
        .then(res => {
          commit('AUTH_USER', res.data.token)
          resolve(res.data)
        })
        .catch(error => reject(error))
    })
  },

  requestOtp : ({commit}, payload) => {
    //console.log('request otp',payload)
    return new Promise((resolve, reject) => {
      axios.post('sales-and-purchase/drcc/send-otp', payload).then((response) => {
        resolve()
      }).catch((e) => {
        reject(e)
      })
    })
  },
  
  verifyOtp : ({commit},payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post('sales-and-purchase/drcc/verify-otp', payload)
        .then(res => {
          resolve()
        })
        .catch(e => {
          reject(e)
        })
    })
  },

  loginOtp : ({commit}, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/auth/login', payload)
        .then(res => {
          commit('LOGIN_OTP', payload)
          axios.post('sales-and-purchase/drcc/send-otp', payload).then(ress=>{
            
          }).catch(err => {
            console.log({err})
          })
          resolve()
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }, 
  
  login: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/auth/login', payload)
        .then(res => {
          // console.log({res})

          //==============================
          const d = new Date();
          let expireTime = d.setSeconds(d.getSeconds() + 61200); //adds 17 hour to login expiration time
          //==============================

          var result = res.data.existingUser;
          // console.log({result})
          router.app.$session.start()
          router.app.$session.set('permission', JSON.stringify(result.roleRef.permission));
          router.app.$session.set('currentRole', result.roleRef.name);
          router.app.$session.set('token', res.data.token);
          router.app.$session.set('userID', result.id);
          router.app.$session.set('expireTime', expireTime)


          // ================================
          // router.app.$session.set('status2FA', '');
          // router.app.$session.set('expiry', expireTime);
          // ================================

          commit('AUTH_USER', res.data.token);
          commit('USER_ID', result.id);
          // update axios authentication token
          axios.defaults.headers.common.Authorization = `Bearer ${res.data.token}`
          resolve(result)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

  fetchUser: ({ commit, state }) => {
    var userID = router.app.$session.get('userID');
    if (userID) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/auth/users/${userID}`)
          .then(res => {
            // console.log('respond',res)
            commit('USER_DETAIL', res.data)
            resolve()
          })
          .catch(error => reject(error))
      })
    }
    
  },

  getUser: ({ commit, state }, userId) => {
    return axios.get(`/auth/users/${userId}`);
  },
  
  fetchAllUser: ({ commit },params) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/auth/users', {
          params: params,
        })
        .then((res) => {
          commit('FETCH_USER', res.data.data)
          resolve(res.data)
        })
        .catch(err => reject(err))
    })
  },
  // getAllUsers: (context, payload) => {
  //   return new Promise((resolve, reject) => {
  //     axios.get('/auth/users', ).then((response) => {
  //       resolve(response.data)
  //     }).catch((e) => {
  //       console.log(e)
  //       reject(e)
  //     })
  //   });
  // },

  newUser: (context, payload) => {
    return new Promise((resolve, reject) => {
      axios.post('auth/users', Object.assign(payload, { app: 'DRCC' })).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    });
  },

  deleteUser: (context, idUser) => {
    return new Promise((resolve, reject) => {
      axios.delete(`/auth/users/${idUser}`).then((response) => {
        resolve();
      }).catch((e) => {
        console.log(e)
        reject(e)
      })
    })
  },

  updateUser: ({ commit }, { id, data }) => {
    return new Promise((resolve, reject) => {
      axios
        .put('/auth/users/' + id, data)
        .then(res => {
          // res.data.roleRef = data.roleRef;
          // commit('UPDATE_USER', res.data)
          resolve(res.data)
        })
        .catch(err => reject(err))
    })
  },

  newUserRandomPW: (context, payload) => {
    return new Promise((resolve, reject) => {
      axios.post('auth/new-user', Object.assign(payload, { app: 'DRCC' })).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    });
  },
  
  getUsersBy: (context, payload) => {
    return new Promise((resolve, reject) => {
      axios.get('/auth/users/by', {
        params: Object.assign(payload, { app: 'DRCC' })
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        console.log(e)
        reject(e)
      })
    });
  },

  //ini gatau apa bedanya dengan yang bawah
  changePassword: (context, payload) => {
    return axios.put('auth/update-password', { ...payload }).then((response) => {
      return true;
    }).catch((e) => {
      console.log(e);
      return false;
    })
  },

  changeUserPassword: (context, payload) => {
    return axios.put('auth/update-user-password', payload).then((response) => {
      console.log(response.data);
      return true;
    }).catch((e) => {
      console.log(e)
      return false;
    })
  }
}
