export default {
  FETCH_GST: (state, payload) => {
    state.gst = payload
  },
  FETCH_CURRENT_GST: (state, payload) => {
    state.currentGST = payload
  },
  ADD_GST: (state, payload) => {
    state.gst.push(payload)
  },
  UPDATE_GST: (state, payload) => {
    let gst = state.gst.find(u => u.id === payload.id)
    for (let [key, value] of Object.entries(payload)) {
      if (gst[key] && gst[key] !== value) {
        gst[key] = value
      }
    }
  },
  DELETE_GST: (state, payload) => {
    let i = state.gst.findIndex(u => u.id === payload)
    state.gst.splice(i, 1)
  }
}